import { useAppState } from 'shared/state';
import config from '../../../config';
import { storage } from '../../../shared/services';
import { useMemo } from 'react';

export function useCaseExtraParams({ statusId }: { statusId: number | null }) {
	const { companyId, searchQuery, caseType, selectedCaseFilter, statusReverseState } =
		useAppState();

	//TODO: #warning: may be performance issue later.
	const parsedSelectedCaseFromLS = JSON.parse(storage.get('selectedCaseFilter') || '{}')[caseType];

	const selectedFilterTemplate = useMemo(
		() => selectedCaseFilter[caseType] || parsedSelectedCaseFromLS,
		[caseType, selectedCaseFilter.Client, selectedCaseFilter.Staff, parsedSelectedCaseFromLS]
	);
	const isStaffCaseTypeSelected = caseType === config.STAFF;
	const staffCaseSearchParams = useMemo(
		() => ({ as_business_company: 'true', as_client_company: 'true' }),
		[]
	);
	const isSearchingStaffCases = searchQuery.length && isStaffCaseTypeSelected;
	const currentStatusReverseState = statusReverseState?.[statusId as number];

	const defaultParams = useMemo(() => {
		return {
			search: searchQuery,
			client_company:
				isStaffCaseTypeSelected && !selectedFilterTemplate && !searchQuery ? companyId : '',
			is_default: selectedFilterTemplate?.is_default ? 'true' : '',
			is_staff: selectedFilterTemplate?.is_staff ? 'true' : '',
			...{ ...(statusId ? { status: statusId } : {}) },
			...{ ...(isSearchingStaffCases ? staffCaseSearchParams : {}) },
			...{ ...(currentStatusReverseState ? { reverse: 'true' } : {}) }
		};
	}, [
		companyId,
		isStaffCaseTypeSelected,
		isSearchingStaffCases,
		searchQuery,
		selectedFilterTemplate,
		staffCaseSearchParams,
		statusId,
		currentStatusReverseState
	]);

	const regularCaseLoadingUrl = selectedFilterTemplate
		? `/${companyId}/cases/filter/`
		: `/${companyId}/cases/as_business/`;

	const url = useMemo(
		() => (searchQuery.length ? `/${companyId}/messages_by_keyword/` : regularCaseLoadingUrl),
		[searchQuery, companyId, regularCaseLoadingUrl]
	);

	const extraParams = useMemo(() => {
		return selectedFilterTemplate && !selectedFilterTemplate.is_default
			? {
					...selectedFilterTemplate.filter_json.query_web,
					...defaultParams
			  }
			: {
					...defaultParams
			  };
	}, [selectedFilterTemplate, defaultParams]);

	return {
		extraParams,
		casesUrl: url,
		selectedFilterTemplate
	};
}
