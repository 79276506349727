import React, { createContext, FC, useContext, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import config from 'config';
import { useAuth } from 'modules/auth/hooks/useAuth';
import { onMessageListener } from 'shared/firebase';
import { IMessage, IRootState } from '../interfaces';
import { useSocketHelpers } from './hooks/useSocketHelpers';
import {
	useCasesRefetch,
	useChatFunctions,
	useFilterTemplateState,
	useNotistack,
	useQueryParams,
	useRouter,
	useUnreadCount
} from '../hooks';
import { useAppState } from 'shared/state';
import { useCaseController } from 'pages/Cases/hooks/useCaseController';
import { useUpdateEntities } from '../hooks';
import { BusinessCompany, Company } from 'modules/user/reducers';
import { useDispatch, useSelector } from 'react-redux';
import userActions from 'modules/user/actions';
import { formSuccess, incrementEntityTotal } from 'modules/entity/actions';
import { toUpperCase } from 'shared/services';
import { useChatMentionUpdate } from '../components/Chat/hooks/useChatMentionUpdate';
import { mentionEventChannel } from 'eventChannels/mention';
import { loadEntitiesSuccess } from 'store/actions/entities';
import { getChatMessagesEntityName } from '../components/Chat/utils';
import { useChatScheduledMsgCountUpdate } from '../components/Chat/hooks/useChatScheduledMsgCountUpdate';
import { SocketEventTypes } from '../typings/socketEvent.types';
import { casesEventChannel } from 'eventChannels/cases';
import { debounce } from 'lodash';
import { checkStringContainsHTML } from 'shared/services/utils/checkStringContainsHTML';

const UserSocketContext = createContext<any>(null!);

const socketUrl = config.API_WS_DEV;

export type DraftMessagePayload = {
	editing_id: number | null;
	entity_id: number;
	entity_type: 'case' | 'ims';
	reply_to: null | IMessage;
	reply_to_id: null | number;
	status_id: number | null;
	text: string;
	type: 'draft_message';
	user_id: number;
};

export type ConfirmStatusType = {
	id: number;
	title: string;
	is_staff: boolean;
};
type StatusNameChangedMsg = {
	company: { id: number; name: string };
	status: string;
	status_id: number;
	confirmed: ConfirmStatusType;
	not_confirmed: ConfirmStatusType;
	is_confirmation_required: boolean;
};

type BusinessTransferMsg = {
	action: string;
	text: string;
	type: 'business_transfer';
	user_id: string;
	company: BusinessCompany;
	user: any;
};

type CaseAssigneeUpdateType = {
	assignee_ids: number[];
	entity_id: string;
	is_staff_case: boolean;
	status: {
		id: number;
		is_staff: boolean;
	};
	user_id: number;
	company_id: string;
	company: any;
	user: any;
	from_user: any;
	business_company: any;
	requesting_company: any;
	content: string;
};

export type StaffEditedType = {
	company_id: string;
	role: 'member' | 'admin' | 'manager';
	type: 'staff_edited';
	user_id: number;
};

type FromToUser = {
	first_name: string;
	last_name?: string;
};

const getUserName = (user: FromToUser) => `${user.first_name} ${user.last_name}`;

const debouncedRefetchCasesByStatus = debounce((statusId: number) => {
	casesEventChannel.emit('refetchCasesByStatus', statusId);
}, 2000);

// eslint-disable-next-line react/function-component-definition
const UserSocketProvider: FC = ({ children }) => {
	const timerRef = useRef<any>(null);
	const { token, userData } = useAuth();
	const {
		updateListUnread,
		showNewMessageNotification,
		changeStatus,
		showStatusChangeNotification,
		showPriorityChangeNotification,
		permissionChangeUpdate,
		updateGroupChatList,
		fetchSingleCase,
		reloadCaseOrGroupChat,
		draftMessageHandler,
		showStaffInvitationNotification,
		connectionRequestHandler,
		casePinHandler,
		updateStatusName,
		handleAssigneeUpdated,
		imsPinUnpinHandler,
		handleStaffEditMessage,
		checkCaseListUpdate,
		handleBusinessTransfer,
		staffListReload,
		businessRequestReload,
		noUnreadLeftForCompany,
		checkCaseForFiltering,
		changePriority,
		removeCaseFromUI,
		getEntityData
	} = useSocketHelpers();
	const { query, removeQueryParams } = useQueryParams();
	const dispatch = useDispatch();
	const { updateEntities } = useUpdateEntities();
	const { showNotification } = useNotistack();
	const { companies } = useAuth();
	const {
		companyId,
		unreadMessagesCount,
		searchQuery,
		history,
		selectedCaseFilter,
		caseType,
		appDeviceUuid
	} = useAppState();
	const { deleteMessage, removeUnread, decrementUnread } = useChatFunctions();
	const { fetchCompanyAllUnreadCounts } = useUnreadCount();
	const { incrementUnreadCaseCount, decrementUnreadCaseCount } = useCaseController();
	const {
		incrementScheduledMsgCount,
		decrementScheduledMsgCount,
		removeEntityScheduledMessagesList
	} = useChatScheduledMsgCountUpdate();
	const { location } = useRouter();
	const {
		hasUnreadFilter,
		filterType,
		isRemovedUserInAssigneeFilterList,
		selectedFilterTemplateId
	} = useFilterTemplateState();
	const entityObjectInReduxState = useSelector((state: IRootState) => state.entity);

	const { readyState, sendJsonMessage } = useWebSocket(`${socketUrl}?token=${token}`, {
		retryOnError: true,
		reconnectAttempts: 120,
		reconnectInterval: 5000,
		onReconnectStop: () => {
			window.location.reload();
		},
		shouldReconnect: (closeEvent) => {
			return true;
			// TODO Reconnect if internet is available;
		},
		onOpen: (event) => {
			sendJsonMessage({
				type: 'user_handshake'
			});

			// const FIVE_MINUTE_TIMEOUT = 300000;
			const FIVE_MINUTE_TIMEOUT = 30000;

			timerRef.current = setInterval(() => {
				sendJsonMessage({ type: 'ping', key: `user_${userData?.id}` });
			}, FIVE_MINUTE_TIMEOUT);
		},
		onClose: (event) => {
			if (process.env.NODE_ENV !== 'production') {
				console.log('user socket closed');
			}
			clearInterval(timerRef.current);
		},
		onMessage,
		onError: (event) => {
			console.error('useWebsocket error');
		}
	});

	const { incrementChatMentions, decrementChatMentions } = useChatMentionUpdate();

	const connectionStatus = {
		[ReadyState.CONNECTING]: 'Connecting',
		[ReadyState.OPEN]: 'Open',
		[ReadyState.CLOSING]: 'Closing',
		[ReadyState.CLOSED]: 'Closed',
		[ReadyState.UNINSTANTIATED]: 'Uninstantiated'
	}[readyState];

	function onMessage(event: MessageEvent<any>) {
		const messageObj = JSON.parse(event.data);
		const messageType = toUpperCase(messageObj?.type) || toUpperCase(messageObj?.action);

		if (process.env.NODE_ENV !== 'production') {
			console.log('user socket messageObj', messageObj);
		}

		switch (messageType) {
			case SocketEventTypes.NEW_MESSAGE:
			case SocketEventTypes.REQUEST_MANAGER:
			case SocketEventTypes.EDIT_MESSAGE:
			case SocketEventTypes.REQUEST_UPDATE: {
				newMessageHandler(messageObj as IMessage);
				break;
			}
			case SocketEventTypes.STATUS_CHANGED: {
				statusChangeHandler(messageObj as IMessage);
				newMessageHandler(messageObj as IMessage, false);
				break;
			}
			case SocketEventTypes.PRIORITY_CHANGED: {
				priorityChangeHandler(messageObj as IMessage);
				break;
			}
			case SocketEventTypes.GROUP_CHAT_PERMISSIONS: {
				groupChatPermissionsChangeHandler(messageObj as IMessage);
				break;
			}
			case SocketEventTypes.ADD_MEMBER: {
				addRemoveMemberGroupChatHandler(messageObj as IMessage, 'ADD_MEMBER');
				break;
			}
			case SocketEventTypes.REMOVE_MEMBER: {
				addRemoveMemberGroupChatHandler(messageObj as IMessage, 'REMOVE_MEMBER');
				break;
			}
			case SocketEventTypes.LEFT_MEMBER: {
				addRemoveMemberGroupChatHandler(messageObj as IMessage, 'LEFT_MEMBER');
				break;
			}
			case SocketEventTypes.GROUP_CLOSED: {
				closeGroup(messageObj as IMessage);
				break;
			}
			case SocketEventTypes.NO_UNREAD_LEFT: {
				//when user do actions outside of case (priority change, request update etc), decrease status unread
				decrementUnreadCaseCount(messageObj.status_id);

				removeUnread({
					entityId: messageObj.entity_id,
					entityType: messageObj.entity_type,
					statusId: messageObj.status_id
				});

				decrementChatMentions({
					entityId: messageObj.entity_id,
					removeAll: true,
					currentMsgCompanyId: messageObj.company_id
				});

				const isInCasesPage = location.pathname.includes('case-messages');

				//do not modify search params if user is not in cases page
				if (!isInCasesPage) {
					return;
				}

				//don't reload cases in current device which read all messages
				if (appDeviceUuid === messageObj?.app_id) {
					return;
				}

				decrementChatMentions({
					entityId: messageObj.entity_id,
					removeAll: true,
					currentMsgCompanyId: messageObj.company_id
				});

				//Main logic to append or remove query params to re-fetch exact statuses cases
				casesEventChannel.emit('refetchCasesByStatus', messageObj?.status_id);

				break;
			}
			case SocketEventTypes.MESSAGE_READ: {
				const { entity_id, tagged_messages, company_id } = messageObj as {
					entity_id: number;
					tagged_messages: number[];
					company_id: number;
				};
				//If case/group is read from another platform but user is the same,
				// we decrement case/group unread count for the user in all platforms. case number: 3321
				if (appDeviceUuid !== messageObj?.app_id && userData?.id === messageObj?.user_id) {
					decrementUnread({
						entityId: messageObj.entity_id,
						entityType: messageObj.entity_type
					});

					//we update "last_read_message" of entity bcs it needs to be updated as well to open case/group from unread correctly
					const entity = messageObj?.entity_type === 'case' ? 'cases' : 'ims-chats';
					updateEntities({
						entity,
						entityId: messageObj?.entity_id,
						updatingData: {
							last_read_message: messageObj?.message_id
						}
					});
				}

				if (Array.isArray(tagged_messages)) {
					decrementChatMentions({
						entityId: entity_id,
						messageIds: tagged_messages,
						currentMsgCompanyId: company_id
					});
				}

				break;
			}
			case SocketEventTypes.NEW_CASE: {
				const {
					case_status_id = null,
					priority = 'NORMAL',
					status = null,
					message_type = 'new_message',
					assignee_ids = [],
					department,
					sub_department
				} = messageObj;
				const selectedFilterTemplate = selectedCaseFilter[caseType];
				const entityName = `AllBusinessCases-${case_status_id}-${caseType}-${
					selectedFilterTemplate ? selectedFilterTemplate.id : 'normal'
				}`;

				/* const itShouldSatisfyFilterTemplate = checkCaseForFiltering({
					priority: priority,
					statusId: status,
					toUser: assignee_ids.includes(userData.id) ? { id: userData.id } : {},
					messageType: message_type,
					taggedUserIds: [],
					departmentId: department?.id,
					subDepartmentId: sub_department?.id,
					entityId: messageObj.entity_id
				}); */

				//3271's fix - prepend own created case to case list and increment total case count.
				/* CASE: 4187 - new created case should be opened even though any filter is active, after closing the case, 
					unmatched case should be removed from the caselist. 
					TODO: watch any side-effects, i removed  itShouldSatisfyFilterTemplate check*/
				if (userData?.id === messageObj?.created_by?.id) {
					//Increase total count of cases in status when new case appended to UI
					dispatch(incrementEntityTotal('cases', entityName));

					fetchSingleCase({
						url: `/${companyId}/cases/as_business/${messageObj.entity_id}/`,
						notLoadedCase: true
					});
				}
				break;
			}
			case SocketEventTypes.DRAFT_MESSAGE: {
				draftMessageHandler(messageObj as DraftMessagePayload);
				break;
			}
			case SocketEventTypes.DELETE_DRAFT_MESSAGE: {
				draftMessageHandler(messageObj as DraftMessagePayload, 'reset');
				break;
			}
			case SocketEventTypes.MESSAGE_PINNED: {
				pinMessageHandler(messageObj);
				break;
			}
			case SocketEventTypes.STAFF_INVITATION_PUSH: {
				showStaffInvitationNotification(messageObj);
				break;
			}
			case SocketEventTypes.CONNECTION_REQUEST: {
				connectionRequestHandler({
					requestText: messageObj.content,
					clientCompany: messageObj.client_company
				});
				break;
			}
			case SocketEventTypes.CASE_PINNED: {
				if (!searchQuery) {
					casePinHandler({
						entityId: messageObj.entity_id,
						statusId: messageObj.status_id,
						isPinning: true
					});
				}

				break;
			}
			case SocketEventTypes.CASE_UNPINNED: {
				casePinHandler({
					entityId: messageObj.entity_id,
					statusId: messageObj.status_id,
					isPinning: false
				});
				break;
			}
			case SocketEventTypes.DELETE_MESSAGE: {
				deleteMessage(messageObj);
				break;
			}
			case SocketEventTypes.IMS_PINNED: {
				imsPinUnpinHandler(messageObj);
				// console.log('request received');
				// {chat_id, user_id} //number
				break;
			}
			case SocketEventTypes.IMS_UNPINNED: {
				imsPinUnpinHandler(messageObj);
				// console.log('request received');
				// {chat_id, user_id} //number
				break;
			}
			case SocketEventTypes.STATUS_NAME_CHANGED: {
				const { status, status_id, confirmed, not_confirmed, is_confirmation_required } =
					messageObj as StatusNameChangedMsg;

				updateStatusName({
					statusId: status_id,
					newStatusTitle: status,
					confirmed,
					not_confirmed,
					is_confirmation_required
				});
				// {chat_id, user_id} //number
				break;
			}
			case SocketEventTypes.MARKED_READ_ALL: {
				(async () => {
					await fetchCompanyAllUnreadCounts();

					const isInCasesPage = location.pathname.includes('case-messages');

					//do not modify search params if user is not in cases page or unread filter is not selected
					if (!isInCasesPage || !hasUnreadFilter) {
						return;
					}

					//TODO: legacy code - remove later
					// //remove 'forceUpdate' before appending other query params
					/* if (reloadUrl?.forceUpdate) {
						removeOneQueryParam('forceUpdate');
					}

					//Main logic to append or remove query params to re-fetch exact statuses cases
					if (reloadUrl?.reloadCases) {
						changeUrlParams({
							reloadStatusId: messageObj.status_id
						});
					} else {
						changeUrlParams(
							{
								reloadCases: true,
								reloadStatusId: messageObj.status_id
							},
							true
						);
					} */

					casesEventChannel.emit('refetchCasesByStatus', messageObj?.status_id);
				})();
				break;
			}
			case SocketEventTypes.CASE_ASSIGNEE_UPDATED: {
				const { assignee_ids, entity_id } = messageObj as CaseAssigneeUpdateType;
				handleAssigneeUpdated({
					entityId: Number(entity_id),
					assigneeIds: assignee_ids
				});
				break;
			}
			case SocketEventTypes.STAFF_EDITED: {
				const { company_id } = messageObj as CaseAssigneeUpdateType;

				(async () => {
					await handleStaffEditMessage(messageObj);
				})();

				//update chat permission after role change
				if ('chat' in query) {
					groupChatPermissionsChangeHandler({
						business_company_id: Number(company_id),
						chat_id: Number(query.chat)
					});
				}

				const isInCasesPage = location.pathname.includes('case-messages');

				//do not modify search params if user is not in cases page
				if (!isInCasesPage) {
					return;
				}

				//!TODO: legacy code - remove later.
				/* if (reloadUrl?.reloadCases) {
					removeMultipleParams(['reloadCases', 'reloadStatusId']);
				}

				if (reloadUrl?.forceUpdate) {
					removeOneQueryParam('forceUpdate');
				} else {
					changeUrlParams(
						{
							forceUpdate: true
						},
						!reloadUrl?.case
					);
				} */

				casesEventChannel.emit('forceUpdateCasesList');

				break;
			}
			case SocketEventTypes.USER_DISCONNECTED: {
				const { user_id, company_id } = messageObj as CaseAssigneeUpdateType;
				handleUserDisconnect(user_id, parseInt(company_id));
				break;
			}
			case SocketEventTypes.BUSINESS_TRANSFER: {
				const { company, user, text } = messageObj as BusinessTransferMsg;
				handleBusinessTransfer(company, user);
				showNotification({
					message: text ?? 'Company owner has been changed'
				});
				break;
			}
			case SocketEventTypes.STAFF_INVITATION_REQUEST: {
				const { from_user, business_company, content } = messageObj as CaseAssigneeUpdateType;
				staffListReload(from_user, business_company);
				showNotification({
					message: content,
					variant: 'info'
				});
				break;
			}
			case SocketEventTypes.USER_INVITATION_REQUEST: {
				const { user_id, requesting_company } = messageObj as CaseAssigneeUpdateType;
				businessRequestReload(user_id, requesting_company);
				break;
			}
			case SocketEventTypes.OWNER_ROLE_INVITATION: {
				if (messageObj.user.id === userData.id) {
					showNotification({
						message: messageObj.request.role,
						variant: 'success'
					});
				}
				break;
			}
			case SocketEventTypes.ADDED_ASSIGNED_USER: {
				//implemented in new_message.message_type:assigned_user
				break;
			}
			case SocketEventTypes.REMOVED_ASSIGNED_USER: {
				//implemented in new_message.message_type:unassigned_user
				break;
			}

			case SocketEventTypes.NO_UNREAD_LEFT_FOR_COMPANY: {
				const { company_id } = messageObj as CaseAssigneeUpdateType;

				noUnreadLeftForCompany(company_id);

				break;
			}
			case SocketEventTypes.USER_UNTAGGED: {
				const { company_id, entity_id, message_ids } = messageObj as {
					company_id: number;
					entity_id: number;
					message_ids: number[];
				};

				if (Array.isArray(message_ids)) {
					decrementChatMentions({
						entityId: entity_id,
						messageIds: message_ids,
						currentMsgCompanyId: company_id
					});
				}

				break;
			}
			case SocketEventTypes.USER_TAGGED: {
				const { company_id, entity_id, message_id } = messageObj as {
					company_id: number;
					entity_id: number;
					message_id: number;
				};

				const isActiveCase = location.search.includes(entity_id.toString());

				//don't increment mention count if chat is active
				if (isActiveCase) {
					return;
				}

				incrementChatMentions({
					entityId: entity_id,
					messageId: message_id,
					currentMsgCompanyId: company_id
				});

				break;
			}

			case SocketEventTypes.SCHEDULED_MESSAGE: {
				updateScheduledMessageInStore({ message: messageObj, isUpdating: false });

				incrementScheduledMsgCount({
					messageId: messageObj?.id,
					entityId: messageObj?.entity_id
				});
				break;
			}

			case SocketEventTypes.EDIT_SCHEDULED_MESSAGE: {
				updateScheduledMessageInStore({ message: messageObj, isUpdating: true });
				break;
			}

			case SocketEventTypes.DELETE_SCHEDULED_MESSAGE: {
				updateScheduledMessageInStore({
					message: { ...messageObj, scheduled_to: true },
					isDeleted: true
				});

				decrementScheduledMsgCount({
					messageId: messageObj?.id,
					entityId: messageObj?.entity_id
				});
				break;
			}

			case SocketEventTypes.NO_SCHEDULED_MESSAGES_LEFT: {
				const selectedFilterTemplate = selectedCaseFilter[caseType];

				removeEntityScheduledMessagesList(messageObj?.entity_id);

				//TODO: 3622 - putting re-fetching cases by status id when filter is active but after 3636 is published live
				//we need to change this re-fetching approach
				if (selectedFilterTemplate) {
					casesEventChannel.emit('refetchCasesByStatus', messageObj?.status_id);
				}
				break;
			}

			case SocketEventTypes.REASSIGNMENT: {
				const { company_id, text, assign_user_id } = messageObj;

				//Re-fetching cases after reassignment
				casesEventChannel.emit('forceUpdateCasesList');

				if (userData?.id === assign_user_id) return;

				//show this notification in the selected company
				if (companyId === company_id) {
					showNotification({
						message: text
					});
				}

				break;
			}

			default:
				return null;
		}

		return null;
	}

	async function newMessageHandler(message: any, hasToShowNotif = true) {
		const {
			entity_type,
			entity_id,
			case_status_id,
			file_type,
			from_user,
			case_number,
			business_company,
			type,
			client_company,
			to_user = {},
			group_name,
			priority,
			sent_time,
			hide_in_business_app = false,
			is_last_message = undefined,
			department,
			sub_department,
			reassign = false
		} = message;
		let { text } = message;

		const isCase = entity_type === 'case';
		//todo: refactoring: should be refactored to isActiveEntity
		const isNotActiveEntity = String(entity_id) !== (isCase ? query?.case : query?.chat);
		const isFromSelectedBusiness = companyId === business_company?.id;
		const messageType = toUpperCase(message.message_type);
		const memberLeftCase = messageType === SocketEventTypes.MEMBER_LEFT;
		const fromUserIsCurrentUser = userData.id === from_user.id;
		const toUserIsCurrentUser = userData?.id === to_user?.id;

		//member deleted from the case and the current user is the removed user.
		const memberIsDeleted = messageType === SocketEventTypes.MEMBER_DELETED && toUserIsCurrentUser;

		const { entityName } = getEntityData('case', case_status_id);

		if (companyId !== business_company?.id) {
			const changedCompanies = companies.map((comp: Company) => {
				if (comp.company.id === business_company?.id) {
					return {
						...comp,
						company: {
							...comp.company,
							unread_cases_count: ++comp.company.unread_cases_count
						}
					};
				} else {
					return comp;
				}
			});
			dispatch(userActions.loadUserCompaniesSuccess(changedCompanies));
		}

		if (
			messageType === SocketEventTypes.MEMBER_DELETED ||
			messageType === SocketEventTypes.MEMBER_ADDED ||
			messageType === SocketEventTypes.MEMBER_LEFT
		) {
			mentionEventChannel.emit('onMembersUpdate');
		}

		//if user is unassigned and filter has that unassigned user, need to re-fetch cases
		if (
			messageType === SocketEventTypes.UNASSIGNED_USER &&
			isRemovedUserInAssigneeFilterList(to_user?.id)
		) {
			casesEventChannel.emit('refetchCasesByStatus', case_status_id);
		}

		//Increment unread count in status header if necessary
		//if member is deleted from case, no need to increment status total unread count
		//fix case number: 3332. added !memberIsDeleted check
		if (
			userData.id !== from_user.id &&
			case_status_id &&
			business_company?.id === companyId &&
			!memberIsDeleted
		) {
			incrementUnreadCaseCount(case_status_id, entity_id);
		}

		//Indicate message as delivered after new message arrive
		if (toUpperCase(message.type) === SocketEventTypes.NEW_MESSAGE) {
			sendJsonMessage({
				type: 'delivered',
				message_id: message.message_id
			});
		}

		//If scheduled message is sent to main chat, need to delete the scheduled message from the scheduled chat
		if (message?.scheduled_message_uuid) {
			updateScheduledMessageInStore({
				message: {
					...message,
					custom_uuid: message?.scheduled_message_uuid,
					scheduled_to: true
				},
				isDeleted: true
			});
		}

		//If case is open and member is deleted
		if (!isNotActiveEntity && memberIsDeleted) {
			removeQueryParams();

			if (memberIsDeleted) {
				text = `${getUserName(message.from_user)} removed ${getUserName(message.to_user)}.`;
				removeCaseFromUI(entity_id, entityName);
			}
		}

		//Update last message in case list case item if edited message is the last one
		if (toUpperCase(type) === SocketEventTypes.EDIT_MESSAGE && is_last_message) {
			const entity = entity_type === 'case' ? 'cases' : 'ims';

			updateEntities({
				entity,
				entityId: entity_id,
				updatingData: {
					last_message_text: text
				}
			});
		}

		// if chat is not open 👇
		if (isNotActiveEntity || !isCase) {
			// Update unread count in caselist|chatlist and lift that case|chat to top of list/status

			const itShouldSatisfyFilterTemplate = checkCaseForFiltering({
				priority,
				statusId: case_status_id,
				//toUser: to_user,
				messageType: message?.message_type,
				taggedUserIds: message?.tagged_users,
				departmentId: department?.id,
				subDepartmentId: sub_department?.id,
				entityId: entity_id
			});
			const { ids: caseIds } = getEntityData('case', case_status_id);
			const caseIsAlreadyInUI = caseIds?.includes(entity_id);
			const removedFromAssigneeMatchesAssigneeFilter =
				messageType === SocketEventTypes.UNASSIGNED_USER &&
				isRemovedUserInAssigneeFilterList(to_user?.id);
			if (
				isFromSelectedBusiness &&
				!memberIsDeleted &&
				(!isCase || itShouldSatisfyFilterTemplate || caseIsAlreadyInUI) &&
				!removedFromAssigneeMatchesAssigneeFilter //prevent re-adding removed from UI case again when manager removes himself from case on active "assignee to me" filter(2812)
			) {
				const waitForUpdateListUnread = async () => {
					return new Promise<void>((resolve, reject) => {
						updateListUnread(
							entity_type,
							entity_id,
							case_status_id,
							text,
							file_type,
							from_user,
							type === 'status_changed',
							isNotActiveEntity,
							!reassign &&
								!selectedCaseFilter[
									caseType
								] /* if filter is active, shouldRefetch param should be false bcs we later fetch cases by status
							when filter is active */
						);
						resolve();
					});
				};

				await waitForUpdateListUnread();

				//if filter is active but not matching cases passes inside, this will refetch caseslist
				if (selectedCaseFilter[caseType]) {
					debouncedRefetchCasesByStatus(case_status_id);
				}
			}

			// do not show foreground notifications for own message
			if (
				userData.id === from_user?.id &&
				messageType !== SocketEventTypes.UNASSIGNED_USER &&
				!memberLeftCase
			) {
				return;
			}

			// Showing notification
			if (entity_type === 'ims') {
				//4399 - disabling push notification temporarily, mute notification permission is not likely to work.
				/* try {
					onMessageListener()
						.then((payload: any) => {
							const messageText = payload?.data?.custom_body;
							const fileType = payload?.data?.file_type;
							const entityType = payload?.data?.entity_type;
							const entityId = Number(payload?.data?.entity_id);

							if (!hide_in_business_app && entityType === 'ims') {
								showNewMessageNotification({
									messageText,
									entity_type: entityType,
									entity_id: entityId,
									case_status_id: null,
									fileType,
									businessCompanyId: business_company.id,
									businessName: business_company.name,
									clientCompanyId: client_company.id,
									group_name
								});
							}
							return;
						})
						.catch((e: Error) => console.error('firebase error', e));
				} catch (e: any) {
					console.error('error promise');
					return;
				} */
			}

			if (messageType === SocketEventTypes.MEMBER_DELETED) {
				text = `${getUserName(message.from_user)} removed ${getUserName(message.to_user)}.`;
				if (toUserIsCurrentUser) {
					removeCaseFromUI(entity_id, entityName, true);
				}
			}
			if (messageType === SocketEventTypes.MEMBER_ADDED) {
				text = `${getUserName(message.from_user)} added ${getUserName(message.to_user)}.`;
			}
			if (messageType === SocketEventTypes.MEMBER_LEFT) {
				text = `${getUserName(message.from_user)} left current case.`;

				if (fromUserIsCurrentUser) {
					removeCaseFromUI(entity_id, entityName, true);
				}
			}
			if (toUpperCase(message.type) === SocketEventTypes.EDIT_MESSAGE) {
				text = `Edited Message: ${text}`;
			}

			if (messageType === SocketEventTypes.UNASSIGNED_USER) {
				const hasToUpdateCaseList = checkCaseListUpdate(to_user, business_company);
				const { ids } = getEntityData('case', message?.case_status_id);
				/**
				 * todo: if this kind message does not come always(sometimes unassigned_user may not come from backend),
				 *       duplicated this logic in 'removed_assigned_user' type
				 */

				if (
					isRemovedUserInAssigneeFilterList(userData?.id) &&
					hasToUpdateCaseList &&
					filterType === 'and'
				) {
					if (to_user?.id === userData.id) {
						const entityName = `AllBusinessCases-${case_status_id}-${caseType}-${
							selectedFilterTemplateId ? selectedFilterTemplateId : 'normal'
						}`;
						//remove case from UI
						removeCaseFromUI(entity_id, entityName);
					}
				}
			}

			if (messageType === SocketEventTypes.ASSIGNED_USER) {
				const hasToUpdateCaseList = checkCaseListUpdate(to_user, business_company);
				if (hasToUpdateCaseList) {
					handleAssigneeUpdated({
						entityId: Number(entity_id),
						assigneeIds: [to_user?.id]
					});
				}
			}

			if (!hasToShowNotif) {
				//don't show notification
				return;
			}

			/** 1) reassign - assigned & unassigned notification should be hidden after staff reassignment
			 * 2) hide_in_business_app - there are notification types that should be hidden in business app
			 * 3) checkStringContainsHTML - if message has HTML it is possibly from google group invitation, need to hide notification for this message
			 */
			const hideNotification = hide_in_business_app || reassign || checkStringContainsHTML(text);

			if (!hideNotification) {
				// Show foreground notification
				showNewMessageNotification({
					messageText: text,
					entity_type,
					entity_id,
					case_status_id,
					fileType: file_type,
					case_number,
					businessCompanyId: business_company?.id,
					businessName: business_company?.name,
					clientCompanyId: client_company.id,
					isBeingDeletedMember: userData.id === to_user?.id,
					group_name
				});
			}

			// QA: Why there was implement 2 various Notifications show logics?
			// 	Answer: In group chat user can disable showing notifications.
			// 	In that case we must not show foreground notification
		}
	}

	function handleUserDisconnect(user_id: number, company_id: number) {
		try {
			if (companyId === company_id && userData?.id === user_id) {
				showNotification({
					message:
						'You will be logged out automatically in 5 seconds due to disconnection from current business',
					variant: 'info'
				});
				setTimeout(() => {
					history.push('/logout');
				}, 5000);
			}
		} catch (e) {
			console.error('Something went wrong during user disconnect', e);
		}
	}

	function statusChangeHandler(message: any) {
		const { entity_type, entity_id, case_status_id, previous_status, status, case_number } =
			message;
		const isNotActiveEntity = String(entity_id) !== query?.case;
		(async function () {
			async function statusChangeWrapper() {
				return new Promise<void>((resolve, reject) => {
					try {
						changeStatus(entity_type, entity_id, previous_status, case_status_id, status);
						resolve();
					} catch (e) {
						reject(e);
					}
				});
			}

			await statusChangeWrapper();

			//We should wait while statusChangeWrapper finishes execution before we execute removeCaseFromUI to remove case
			//that's why we promisified changeStatus function call

			const noUnreadLeftForThisCase = !unreadMessagesCount?.[entity_id];
			const { entityName } = getEntityData('case', case_status_id);
			const isCaseExistInUI =
				entityObjectInReduxState?.cases?.[entityName]?.ids?.includes(entity_id);

			if (hasUnreadFilter && filterType === 'and' && noUnreadLeftForThisCase && isCaseExistInUI) {
				removeCaseFromUI(entity_id, entityName, true);
			}

			if (isNotActiveEntity) {
				showStatusChangeNotification(case_number, status);
			}
		})();
	}

	type UpdateScheduledMessageInStoreArg = {
		message: any;
		isUpdating?: boolean;
		isDeleted?: boolean;
	};

	function updateScheduledMessageInStore({
		message,
		isUpdating = false,
		isDeleted = false
	}: UpdateScheduledMessageInStoreArg) {
		const uuid = message.custom_uuid;
		const entityType = message.entity_type;
		const entityId = message.entity_id;

		const entityName = getChatMessagesEntityName({
			isScheduled: Boolean(message?.scheduled_to),
			entityType,
			entityId
		});

		if (!isDeleted) {
			const entitiesPayload = {
				[`${entityType}Messages`]: {
					[uuid]: { ...message, from_user: userData }
				}
			};

			dispatch(loadEntitiesSuccess(entitiesPayload));
		}

		dispatch(
			formSuccess({
				id: uuid,
				entity: `${entityType}Messages`,
				name: entityName,
				appendData: !isDeleted,
				prependData: false,
				updateData: isUpdating,
				deleteData: isDeleted
			})
		);
	}

	function priorityChangeHandler(message: any) {
		const { entity_type, entity_id, priority, case_status_id, case_number, from_user } = message;
		const isNotActiveEntity = String(entity_id) !== query?.case;
		const hasToShowNotification = isNotActiveEntity && from_user?.id === userData?.id;
		changePriority(entity_type, entity_id, priority, case_status_id);

		if (hasToShowNotification) {
			showPriorityChangeNotification(case_number, priority);
		}
	}

	function groupChatPermissionsChangeHandler(message: any) {
		const { business_company_id, chat_id } = message;

		if (business_company_id === companyId && 'chat' in query) {
			permissionChangeUpdate(business_company_id, chat_id);
		}
	}

	function addRemoveMemberGroupChatHandler(
		message: any,
		type: 'REMOVE_MEMBER' | 'ADD_MEMBER' | 'LEFT_MEMBER'
	) {
		const { to_user, business_company_id, entity_id, custom_title, from_user, entity_type } =
			message;
		const isNotActiveChat = String(entity_id) !== query?.chat;
		const isFromSelectedBusiness = companyId === business_company_id;
		const actionType = toUpperCase(type);

		const isCurrentUserHasBeenAddedOrRemoved = to_user?.id === userData.id;
		const isRemoveMemberTypeAndCurrentUser =
			isCurrentUserHasBeenAddedOrRemoved && actionType === SocketEventTypes.REMOVE_MEMBER;

		let text = '';

		switch (actionType) {
			case SocketEventTypes.ADD_MEMBER: {
				text = `${getUserName(from_user)} added ${getUserName(to_user)} to current group chat`;
				break;
			}
			case SocketEventTypes.REMOVE_MEMBER: {
				text = `${getUserName(from_user)} removed ${getUserName(to_user)} from current group chat`;
				break;
			}
			case SocketEventTypes.LEFT_MEMBER: {
				text = `${getUserName(from_user)} left  current group chat`;
				break;
			}
		}

		mentionEventChannel.emit('onMembersUpdate');
		//if current user is removed from chat, we dont need to update unread count since user is no longer in that chat
		if (isFromSelectedBusiness && !isRemoveMemberTypeAndCurrentUser) {
			updateListUnread(entity_type, entity_id, null, text, null, from_user, false, isNotActiveChat);
		}

		updateGroupChatList({
			businessId: business_company_id,
			type,
			entityId: entity_id,
			text: custom_title,
			isCurrentUserHasBeenAddedOrRemoved,
			showNotification: !message?.hide_in_business_app
		});
		// }
	}
	function closeGroup(message: any) {
		const { business_company_id, entity_id } = message;

		updateGroupChatList({
			businessId: business_company_id,
			type: 'REMOVE_MEMBER',
			entityId: entity_id,
			text: 'Group chat has been deleted',
			isCurrentUserHasBeenAddedOrRemoved: true
		});
	}

	function pinMessageHandler(messageObj: any) {
		if (Number(query?.chat) === messageObj.case_id || Number(query?.case) === messageObj.case_id) {
			const isCase = 'case' in query;
			reloadCaseOrGroupChat({
				entityType: isCase ? 'case' : 'ims',
				entityId: messageObj.case_id
			});
		}
	}

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const contextValues = {
		message: 'hello',
		sendJsonMessage
	};

	return <UserSocketContext.Provider value={contextValues}>{children}</UserSocketContext.Provider>;
};

export default UserSocketProvider;

export function useUserSocket() {
	const context = useContext(UserSocketContext);

	if (!context) throw new Error('useUserSocket must be used within the AppStateProvider');

	return context;
}
